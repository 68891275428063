// Slider

import Glide from '@glidejs/glide';

import	intlTelInput from 'intl-tel-input';

let homeSelector = document.querySelector('.home-header');
if(homeSelector){
	const updateMenu = () => {
		let slide = document.querySelector('.slide-' + glideHome.index);
		let menuHeader = document.querySelector('.menu-container');
		if(slide.classList.contains("picture")) {
			homeSelector.classList.add('light');
			menuHeader.classList.add('light');
		} else {
			homeSelector.classList.remove('light');
			menuHeader.classList.remove('light');
		}
	}
	let glideHome = new Glide('.glide-home', {
		gap: 0
	});
	glideHome.on(['mount.before', 'run'], function() {
		updateMenu();
	});
	glideHome.mount();

	//new Glide('.glide-studies').mount();
	let glideStudies = new Glide('.glide-studies');
	glideStudies.mount();
	
	let glideTestemonials = new Glide('.glide-testimonials', {
		type: 'carousel',
		focusAt: 'center',
		perView: 3,
		gap: 10,
		autoplay: 3000,
		breakpoints: {
			992: {
				perView: 2
			},
			768: {
				perView: 1
			}
		}
	});
	glideTestemonials.mount();
	let glideMap = new Glide('.glide-map', {
		animationDuration: 0,
		rewindDuration: 0,
		autoplay: 3000
	});
	glideMap.mount();
}


let covidPage = document.querySelector('.page-covid');

if(covidPage)
{
	var input = document.querySelector("#inputPhone");

	intlTelInput(input, {
		allowExtensions: true,
		formatOnDisplay: true,
		autoFormat: true,
		autoPlaceholder: true,
		defaultCountry: "auto",
		ipinfoToken: "yolo",
		initialCountry : 'TN',
		nationalMode: false,
	});

	let sendPhone = document.querySelector('.sendPhone');
	sendPhone.addEventListener('click', function(e) {
		e.preventDefault();
		var phone = document.querySelector('#inputPhone').value;
		document.querySelector('.sendPhone').setAttribute("disabled", true);

		if(!validatePhone(phone))
		{
			document.querySelector('.page-covid .succes-message').style.display = 'none';
			document.querySelector('.page-covid .error-message').style.display = 'block'
			document.querySelector('.sendPhone').removeAttribute("disabled");
		}
		else
		{
			document.querySelector('.page-covid .succes-message').style.display = 'block';
			document.querySelector('.page-covid .error-message').style.display = 'none';
			document.getElementById("covid-form").submit();
		}
		
	});
}


require('scrollpos-styler');

let hamburger = document.querySelector('.hamburger--collapse');
let navbarCollapse = document.querySelector('.navbar-collapse');
let body = document.querySelector('body');
hamburger.addEventListener('click', function(e) {
	e.preventDefault();
	hamburger.classList.toggle('is-active');
	navbarCollapse.classList.toggle('open');
	body.classList.toggle('mobile-menu-open');
});

let subscribe = document.querySelector('.subscribe');

let validateEmail = function($email) {
	var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
	return emailReg.test( $email );
} 

let validatePhone = function($phone) {
	var phoneReg = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
	return phoneReg.test( $phone );
} 
// subscribe.addEventListener('click', function(e) {
// 	e.preventDefault();
// 	var email = $('#inputEmail').val();
// 	$('.subscribe').attr("disabled", true);

// 	if(validateEmail(email))
// 	{
// 		$.ajax({
// 			type: 'post',
// 			url: '/newsletter',
// 			data: {email: email},
// 		})
// 		.done(function (data) {
		
// 			$('#inputEmail').val('');
// 			$('.succes-message').show()
// 			$('.error-message').hide()
// 			$('.subscribe').attr("disabled", false);
// 		})
// 	}
// 	else
// 	{
// 		$('.succes-message').hide()
// 		$('.error-message').show()
// 		$('.subscribe').attr("disabled", false);
// 	}
	
// });



const serviceMenu = (() => {
	let body;
	let menu;
	
	const init = () => {
		body = document.querySelector('body');
		menu = document.querySelectorAll('.btn-service-menu');
		applyListeners();
	}
	
	const applyListeners = function() {
		[].forEach.call(menu, function(el) {
			el.addEventListener('click', (e) => toggleClass(body, 'nav-active', e));
		})
	}
	
	const toggleClass = (element, stringClass, e) => {
		e.preventDefault();
		close = document.querySelector('.hamburger--services');
		close.classList.toggle('open');
		if(element.classList.contains(stringClass)) {
			element.classList.remove(stringClass);
			let navContent = document.querySelector('.nav__content')
			navContent.style.display = 'none';
			close.style.display = 'none';
			setTimeout(function() {
				navContent.style.display = 'block';
				close.style.display = 'block';
			}, 1000);
		} else {
			element.classList.add(stringClass);
		}
	}
	
	init();
})();

const serviceMenu2 = (() => {
	let body;
	let menu;
	
	const init = () => {
		body = document.querySelector('body');
		menu = document.querySelectorAll('.btn-solutions-menu');
		applyListeners();
	}
	
	const applyListeners = function() {
		[].forEach.call(menu, function(el) {
			el.addEventListener('click', (e) => toggleClass(body, 'nav-active2', e));
		})
	}
	
	const toggleClass = (element, stringClass, e) => {
		e.preventDefault();
		close = document.querySelector('.hamburger--solutions');
		close.classList.toggle('open');
		if(element.classList.contains(stringClass)) {
			element.classList.remove(stringClass);
			let navContent = document.querySelector('.nav2__content')
			navContent.style.display = 'none';
			close.style.display = 'none';
			setTimeout(function() {
				navContent.style.display = 'block';
				close.style.display = 'block';
			}, 1000);
		} else {
			element.classList.add(stringClass);
		}
	}
	
	init();
})();

const myTimeout = setTimeout(console.clear(), 600);

window.pfe = function(test){
	if (test == null){
		console.log("%cError : missing argument :)", "color:#cc0000; font-size:20px");
	} else if (test != "2024") {
		console.log("%cError : Param must be equal to 2024 :)", "color:#cc0000; font-size:20px");
	} else {
		console.log("%c↓", "color:#fce526; font-size:25px");
		console.log("%chttps://satoripop.com/pdf/Satoripop-pfe-book-2024.pdf", "color:#cc0000; font-size:20px");
	}

}

console.log("%cCall the PFE function 👨‍💻", "color:#fce526; font-size:50px");